<script setup lang="ts">
import { isVNode } from 'vue';
import { useToast } from './toast';
import {
	Toast,
	ToastDescription,
	ToastProvider,
	ToastTitle,
	ToastViewport,
	ToastClose,
	ToastImage,
} from '.';
import ToastIcon from './ToastIcon.vue';

interface ToastProps {
	class?: string;
	variant?: string;
	screen?: 'desktop' | 'mobile';
	showClose?: boolean;
	position?: 'top' | 'bottom';
}

const { toasts } = useToast();

const props = withDefaults(defineProps<ToastProps>(), {
	class: '',
	variant: 'default',
	screen: 'desktop',
	showClose: true,
	position: 'bottom',
});
</script>

<template>
	<ToastProvider>
		<Toast
			v-for="toast in toasts"
			:key="toast.id"
			v-bind="toast"
			class="z-[60] flex items-center gap-md"
		>
			<ToastIcon v-if="toast.icon">
				<component :is="toast.icon" />
			</ToastIcon>
			<ToastImage v-if="toast.img">
				<component :is="toast.img" />
			</ToastImage>
			<component :is="toast.left" v-if="toast.left" />
			<div class="grid">
				<ToastTitle v-if="toast.title">
					{{ toast.title }}
				</ToastTitle>
				<template v-if="toast.description">
					<ToastDescription v-if="isVNode(toast.description)">
						<component :is="toast.description" />
					</ToastDescription>
					<ToastDescription v-else>
						{{ toast.description }}
					</ToastDescription>
				</template>
				<ToastClose v-show="props.showClose" />
			</div>
			<component :is="toast.action" />
		</Toast>
		<ToastViewport :position="position" />
	</ToastProvider>
</template>
