<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import { ToastClose, type ToastCloseProps } from 'radix-vue';
import { PhX } from '@phosphor-icons/vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<
	ToastCloseProps & {
		class?: HTMLAttributes['class'];
	}
>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});
</script>

<template>
	<ToastClose
		v-bind="delegatedProps"
		:class="cn('absolute top-md right-lg rounded-md', props.class)"
	>
		<PhX class="h-3xl w-3xl" />
	</ToastClose>
</template>
