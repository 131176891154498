<script setup lang="ts">
import { computed, type HTMLAttributes } from 'vue';
import { ToastViewport, type PrimitiveProps } from 'radix-vue';

import { cn } from '@laam/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const toastViewPortVariants = cva(
	'fixed right-0 left-0 flex justify-center z-[60]',
	{
		variants: {
			position: {
				bottom: 'bottom-[100px]',
				top: 'top-[100px]',
			},
		},
		defaultVariants: {
			position: 'bottom',
		},
	},
);

export type ToastViewportVariants = VariantProps<typeof toastViewPortVariants>;

export interface ToastViewportProps extends PrimitiveProps {
	class?: HTMLAttributes['class'];
	position?: ToastViewportVariants['position'];
}

const props = withDefaults(defineProps<ToastViewportProps>(), {
	position: 'bottom',
	class: '',
});

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});
</script>

<template>
	<ToastViewport
		v-bind="delegatedProps"
		:class="cn(toastViewPortVariants({ position }), props.class)"
	/>
</template>
