<script setup lang="ts">
import { ToastDescription, type ToastDescriptionProps } from 'radix-vue';

const props = defineProps<ToastDescriptionProps>();
</script>

<template>
	<ToastDescription v-bind="props">
		<slot />
	</ToastDescription>
</template>
